import { createContext, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "./UserProvider";
import { AuthContext } from "./AuthProvider";
import $ from "jquery";
import API from "../API.json";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { token, id } = useContext(AuthContext);
  const [previousChat, setPreviousChat] = useState(0);
  const [currentChat, setCurrentChat] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [savedInput, setSavedInput] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [sfiles, setFiles] = useState(null);

  useEffect(() => {
    if (intervalId != null) {
      clearInterval(intervalId);
    }
    setIntervalId(setInterval(getChat, 1000));
  }, [chatData]);

  useEffect(() => {
    getAll();
  }, [currentChat]);

  const sendChat = (val, files = null) => {
    var ch = chatData;
    ch.push({
      "id": 1241,
      "user1": id,
      "user2": currentChat[0],
      "message": val,
      "sent_at": "2024-09-24 10:36:24"
  })
    setChatData(ch)
    $.ajax({
      type: "POST",
      url: `${API.url}/Chat/send/${id}/${currentChat[0]}`,
      data: {
        message: val,
        token: token
      },
      success: (response) => {
        document.getElementById("chatbox").scrollTo(0, document.getElementById("chatbox").scrollHeight);
      }
    });

    if (sfiles == null) {
      return;
    }

    var fdata = new FormData();
    fdata.append("token", token);
    Array.from(sfiles).forEach((file, idx) => {
      fdata.append(`files[${idx}]`, file);
    });
    $.ajax({
      type: "POST",
      url: `${API.url}/upload-file`,
      data: fdata,
      processData: false,
      success: (response) => {
        // Handle successful message
      }
    });

    // sajnos most jelenleg nem jó (user hiánya miatt vettem ki, majd ha leszz vissza tenni)
  };

  const getAll = () => {
    if (currentChat != null) {
      $.ajax({
        type: "GET",
        url: `${API.url}/Chat/get/${token}/${id}/${currentChat[0]}`,
        success: (response) => {
          setChatData(response);
        }
      });
    }
  };

  const getChat = () => {
    if (currentChat != null) {
      $.ajax({
        type: "POST",
        url: `${API.url}/Chat/checkNewMessage`,
        data: {
          u1: id,
          u2: currentChat[0],
          nl: chatData.length,
          token: token
        },
        success: (response) => {
          const updatedChatData = [...chatData, ...response];
          var length = chatData.length;
          setChatData(updatedChatData);
          if(updatedChatData.length != length)
            document.getElementById("chatbox").scrollTo(0, document.getElementById("chatbox").scrollHeight);

        }
      });
    }
  };

  return (
    <ChatContext.Provider
      value={{
        currentChat,
        setCurrentChat,
        currentMessages,
        setCurrentMessages,
        setPreviousChat,
        savedInput,
        setSavedInput,
        sendChat,
        chatData,
        setFiles
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};