import { useContext } from "react";
import { GoPencil } from "react-icons/go";
import { GoSearch } from "react-icons/go";
import { GoBell } from "react-icons/go";
import { FaList } from "react-icons/fa";
import { ChatContext } from "../Providers/ChatProvider";
import { UserContext } from "../Providers/UserProvider";
import { FriendContext } from "../Providers/FriendProvider";
function Friends() {
  const { setCurrentChat, currentChat } = useContext(ChatContext);
  const { name, avatar } = useContext(UserContext);
  const { friends } = useContext(FriendContext);

  const friendCard = (i) => {
    var av = i.avatar == "default" ? "https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg" : i.avatar;
    var msg = i.message ? i.message.includes("picture") ? "Kép" : i.message : null;
    return (<div className="fs" onClick={() => setCurrentChat([i.userId, i.name, av])}>
      <div className="f">
        <div className="d-flex w-100">
          <div className="av" style={{ backgroundImage: `url(${av})` }}></div>
          <span className="nm">{i.name}</span>
        </div>
        <span className="nms">0</span>
      </div>
      <span className="lm">{msg}</span>
    </div>);
  }

  return (
    <>
      <div className="friends">
        <div className="user">
          <div className="d-flex justify-content-between">
            <div className="d-inline-flex w-100">
              <span
                className="p"
                style={{ backgroundImage: `url(${avatar})` }}></span>
              <span className="n">{name}</span>
            </div>
            <div className="edit d-flex">
              <div>  
                <GoPencil size={20} className="cursor ms-3" />
              </div>
              <div>
                 <GoBell size={20} className="cursor ms-3" />
                 <div className="ac">0</div>
              </div>
              <div>  
                <FaList size={20} className="cursor ms-3" />
                
              </div>

            </div>
          </div>
          <div className="search">
            <div className="d-flex">
              <div className="si">
                <GoSearch size={25} />
              </div>
              <input type="text" placeholder="Keress barátokat..." />
            </div>
          </div>
        </div>
        <div className="line mb-2"></div>
        {friends.map((i) => (friendCard(i)))}
        {/* <div className="fs">
          <div className="f">
            <div className="d-flex w-100">
              <div className="av"></div>
              <span className="nm">Szabó Gábor</span>
            </div>
            <span className="nms">0</span>
          </div>
          <span className="lm">You: Hello Word...</span>
        </div>*/}
      </div>
    </>
  );
}
export default Friends;
