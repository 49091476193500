import { useContext, useEffect, useId, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../Providers/AuthProvider";
function Register() {
  const { ARegister, GetError } = useContext(AuthContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordAginRef = useRef();
  const nameRef = useRef();
  const [error, setError] = useState();
  const navigator = useNavigate();
  const btnRef = useRef();

  const HandleRegister = () => {
    ARegister(
      nameRef.current.value,
      emailRef.current.value,
      passwordRef.current.value,
      passwordAginRef.current.value
    );
  };
  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (e.key == "Enter") {
        if (btnRef.current != null) {
          // hogy ne errorozzon
          HandleRegister();
        }
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="login register">
          <br />
          <br />
          <br />
          <br />
          <div className="d-flex justify-content-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqlJQzmkfhZdSFEoMeNxyFcEzrBbQn5_Fh3A&s"
              alt=""
            />
          </div>
          <h3 className="text-center mt-2">Regisztráció</h3>
          <div className="d-flex justify-content-center">
            <div>
              <div className="mt-3">
                <input type="text" placeholder="Név" ref={nameRef} />
              </div>
              <div className="mt-3">
                <input type="text" placeholder="Email" ref={emailRef} />
              </div>
              <div className="mt-3">
                <input type="password" placeholder="Jelszó" ref={passwordRef} />
              </div>
              <div className="mt-3">
                <input
                  type="password"
                  placeholder="Jelszó ismét"
                  ref={passwordAginRef}
                />
              </div>
              <p className="text-center text-danger fw-bold my-2">{error}</p>
              <div className="mt-3">
                <button
                  className="login_btn"
                  id="register_"
                  onClick={HandleRegister}
                  ref={btnRef}>
                  Regisztráció
                </button>
              </div>
              <div className="mt-3">
                <a href="" onClick={() => navigator("/")}>
                  Már van fiókom, vissza a bejelentkezéshez!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
