import "./Styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import { UserProvider } from "./Providers/UserProvider";
import { AuthProvider } from "./Providers/AuthProvider";
import { ChatProvider } from "./Providers/ChatProvider";
import { FriendProvider } from "./Providers/FriendProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthProvider>
              <ChatProvider>
                  <FriendProvider>
                <UserProvider>
                <Main />
                </UserProvider>
              </FriendProvider>
              </ChatProvider>
            </AuthProvider>
          }
        />
        <Route path="*" element={<AuthProvider></AuthProvider>} />
      </Routes>
    </BrowserRouter>
  </>
);
