import Chat from "../Components/Chat";
import ChatFriendDetails from "../Components/ChatFriendDetails";
import Friends from "../Components/Friends";
function Main() {
  return (
    <>
      <div className="row g-3">
        <div className="col-3">
          <Friends />
        </div>
        <div className="col-6">
          <Chat />
        </div>
        <div className="col-3 chatfrienddetails"><ChatFriendDetails /></div>
      </div>
    </>
  );
}
export default Main;
