import { createContext, useContext, useEffect, useRef, useState } from "react";

import { AuthContext } from "./AuthProvider";
import $ from "jquery";
import API from "../API.json";
import { ChatContext } from "./ChatProvider";

export const FriendContext = createContext();

export const FriendProvider = ({ children }) => {
  const { token, id } = useContext(AuthContext);
  const { setCurrentChat, currentChat } = useContext(ChatContext);
  const [friends, setFriends] = useState([]);
  const [intervalId, setIntervalId] = useState(null);


  const getFriends = () => {
    $.ajax({
      type: "POST",
      url: `${API.url}/User/get-friends`,
      data: {
        token: token,
        id: id
      },
      success: (response) => {
        setFriends(response);
        if (currentChat.length == 0)
          setCurrentChat([response[0].userId, response[0].name, response[0].avatar == "default" ? "https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg" : response[0].avatar])
      }
    })
  }

  useEffect(() => {
    if (intervalId != null) {
      clearInterval(intervalId);
    }
    setIntervalId(setInterval(getFriends, 1000));

  }, [friends])

  return (
    <FriendContext.Provider
      value={{
        getFriends,
        friends
      }}>
      {children}
    </FriendContext.Provider>
  );
};
