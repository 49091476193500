import { useContext, useRef, useState } from "react";
import { ChatContext } from "../Providers/ChatProvider";
import { UserContext } from "../Providers/UserProvider";
import { CiSearch } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { IoIosAttach } from "react-icons/io";
import { IoIosNotificationsOutline, IoIosSend } from "react-icons/io";
import { AuthContext } from "../Providers/AuthProvider";
import { FriendContext } from "../Providers/FriendProvider";
function Chat() {
  const { avatar } = useContext(UserContext);
  const { chatData, currentChat, sendChat, setFiles} = useContext(ChatContext);
  const { id } = useContext(AuthContext);
  const { friends } = useContext(FriendContext);
  const inputRef = useRef();
  var attacher = document.createElement("input");
  attacher.setAttribute("multiple", "");

  attacher.type = "file";
  attacher.onchange = (e) => {
    setFiles(e.target.files);
  };
  
  //return <>{selectedChat}</>;

  const image = (link) => {
    return (<img src={link} className="sentmedia" onClick={() => window.open(link, "_blank")} />)
  }

  const message = (i) => {
    if (i.user1 == id)
      return (<div className="u1m">
        <div className="msg">
          {i.message.includes("picture") ? image(i.message.split('$')[1]) : i.message}
        </div>
        <div className="p" style={{ backgroundImage: `url(${avatar})` }}></div>
      </div>);
    else
      return (<div className="u2m">
        <div className="p" style={{ backgroundImage: `url(${currentChat[2]})` }}></div>
        <div className="msg">
          {i.message.includes("picture") ? image(i.message.split('$')[1]) : i.message}
        </div>
      </div>);
  }
  const HandleSend = () => {
    const ms = inputRef.current.value;
    inputRef.current.value = "";
    if(ms.length != 0) sendChat(ms);
  }

  const OpenAttacher = () => {
    attacher.click();
  }

  return (
    <>
      <div className="chat">
        <div className="fp">
          <div className="d-flex w-100">
            <div className="p" style={{ backgroundImage: `url(${currentChat[2]})` }}></div>
            <span className="nm">{currentChat[1]}</span>
          </div>
          <div className="d-flex w-100 icons ">
            <div className="ms-auto">
              <CiSearch size={30} className="hover" />
              <CiHeart size={30} className="hover" />
              <IoIosNotificationsOutline size={30} className="hover" />
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="chatbox" id="chatbox">
          {chatData.map((i) => (message(i)))}
        </div>
        <div className="chatcontrols">
            
          <div className="messagebox">
            <div className="d-flex">
              <input type="text" placeholder="Aa" className="messageinput" ref={inputRef} />
              <div className="si">
              <IoIosSend size={25} onClick={HandleSend} className="cursor"/>
              <IoIosAttach size={25} onClick={/*OpenAttacher*/null} className="cursor"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Chat;
