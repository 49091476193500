import { useContext } from "react";
import { ChatContext } from "../Providers/ChatProvider";
import { GoSearch } from "react-icons/go";

function ChatFriendDetails() {
  const { currentChat, chatData } = useContext(ChatContext);

  const loadAttachedImage = (i) => {
    if (!i.message.includes("picture"))
      return;
    return <div className="attachedimage" style={{backgroundImage: `url(${i.message.split('$')[1]})`}}></div>
  }

  return <>
  <div className="searchholder">
  <div className="search">
            <div className="d-flex">
              <div className="si">
                <GoSearch size={25} />
              </div>
              <input type="text" placeholder="Üzenet keresése.." />
            </div>
          </div>
          </div>
          <div className="userbodyholder">
            <div src={currentChat[2]} style={{backgroundImage: `url(${currentChat[2]})`}} className="img"></div>
            <p className="name">{currentChat[1]}</p>
            <p className="status">Jelenleg elérhető</p>
            <div className="attachments">
              {chatData.map((i) => (loadAttachedImage(i)))}
            </div>
          </div>
  </>;
}
export default ChatFriendDetails;
