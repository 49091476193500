import { createContext, useState } from "react";
import Cookies from "js-cookie";
import LoginForm from "../Pages/Login";
import { redirect, useLocation } from "react-router-dom";
import RegisterForm from "../Pages/Register";
import API from "../API.json";
import $ from "jquery";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState(Cookies.get("token"));
  const [id, setId] = useState(Cookies.get("id"));
  const emailRegx = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/;

  const getError = () => {
    return errorMessage;
  };

  const ALogin = (email, password) => {
    if (!emailRegx.test(email)) {
      setErrorMessage("Az email cím nem megfelelő! (minta@domain.com)");
      return;
    }

    $.ajax({
      type: "POST",
      url: `${API.url}/User/login`,
      data: { email: email, password: password },
      success: function (response) {
        Cookies.set("id", response.id);
        Cookies.set("token", response.token);
        setToken(response.token);
        setId(response.id);
      },
      error: (err) => {
        setErrorMessage(err.responseJSON.reason);
      },
    });
  };

  const ARegister = (name, email, password, passwordAgain) => {
    if (password != passwordAgain) setErrorMessage("A jelszavak nem egyeznek!");
    if (!emailRegx.test(email)) {
      setErrorMessage("Az email cím nem megfelelő! (minta@domain.com)");
      return;
    }

    $.ajax({
      type: "POST",
      url: `${API.url}/User/register`,
      data: { email: email, password: password, name: name },
      success: (response) => {
        window.location = "/login?registersuccess=true";
      },
      error: (err) => {
        setErrorMessage(err.responseJSON.reason);
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{ ALogin, isSuccess, getError, ARegister, token, id }}>
      {Cookies.get("id") == null ? (
        window.location.pathname == "/register" ? (
          <RegisterForm />
        ) : (
          <LoginForm />
        )
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
