import { useState, createContext, useEffect, useContext, act } from "react";
import Cookies from "js-cookie";
import $ from "jquery";
import API from "../API.json";
import { AuthContext } from "./AuthProvider";
import { FriendContext } from "./FriendProvider";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [userLoaded, setLoaded] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState("");
  const { token, id } = useContext(AuthContext);
  const { getFriends } = useContext(FriendContext);

  useEffect(() => {
    FetchUser();
  }, []);
  useEffect(() => {
    setLoaded(true);
    getFriends();
  }, [avatar]);

  const FetchUser = () => {
    $.ajax({
      type: "POST",
      url: `${API.url}/User/fetch`,
      data: { token: token, id: id },
      success: (response) => {
        response = response[0];
        setName(response.name);
        if (response.avatar == "default") {
          setAvatar(
            "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          );
        } else {
          setAvatar(response.avatar);
        }
      },
    });
  };
  const UploadAvatar = (file) => {
    var formData = new FormData();
    formData.append("token", token);
    formData.append("id", id);
    formData.append("file", file);

    $.ajax({
      type: "POST",
      url: `${API.url}/User/profile/upload-avatar`,
      data: formData,
      success: (response) => {
        FetchUser();
      },
      error: (err) => {
        alert(err.responseJSON.reason);
      },
    });
  };
  return (
    <UserContext.Provider
      value={{ name, avatar, FetchUser, UploadAvatar, currentAvatar }}>
      {userLoaded ? children : "user loading, please wait"}
    </UserContext.Provider>
  );
};
